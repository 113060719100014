<!-- Settings -->
<template>
<div>
  <el-row >
    <el-col :span="24" style="text-align:center;">
      <span class="text_header">Welcome to Tevola Management</span>
    </el-col>
    
  </el-row>
  <el-row class="mt-5">
  <el-col :span="5"  :gutter="12" v-for="websitedetail in list" v-bind:key="websitedetail.id" :offset="1" >
    <el-card shadow="always">
      <h2 style="text-align:center"><a v-bind:href ="websitedetail.website_url" target="_blank">{{websitedetail.website_name}}</a></h2>
      <a v-bind:href ="websitedetail.website_url" target="_blank">
        <span class="imglogo" v-loadimage="websitedetail.website_logo"></span></a>
    </el-card>
  </el-col>  
</el-row>
</div>
</template>

<style>
.bg-dark {    background-color: #ffffff !important; text-align: center !important;}
.imglogo img{width: 90%; height: 50px;}
.text_header{
  font-size: 35px;
  font-weight: 600;
  font-family: 'Flaticon';
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getAllActiveSites } from "@/api/website";

export default {
  name: "dashboard",
   data() {
      return { 
        list: [],
         loading: false,
         sort: null,
         pagination: {},
         q: null,
         image:''
      }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  created() {
    this.fetchData();
  },
  components: {
   
  },
  methods: {
     fetchData(p) {
      this.loading = true
      let params = { page: p }
      if(this.q){
        params.q = this.q
      }
      if(this.sort){
        params.sort = this.sort;
      }
      getAllActiveSites(params).then(response => {
        this.list = response.data.data
        this.loading = false
      })
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>

